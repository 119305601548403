/* ADD GLOBAL STYLES HERE - FONTS, HEIGHTS, OVERRIDES, ETC */
#root {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  font-family: "Varela Round", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
}
